import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
//import Img from "gatsby-image"
import Layout from "../layouts"

class PostTemplate extends Component {
    render() {
        const post = this.props.data.wordpressPost

        return (
            <Layout>
                <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                <p dangerouslySetInnerHTML={{ __html: post.content }} />
            </Layout>
        )
    }
}

PostTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPost(id: {eq: $id}) {
            author
            content
            title
        }
    }
`
/*   query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      ...PostIcons
      acf {
        page_builder_post {
          __typename
          ... on WordPressAcf_post_photo {
            photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_image_gallery {
            pictures {
              picture {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 680) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
` */