import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

//import { rhythm, scale } from "../utils/typography"

const containerStyle = {
    maxWidth: 700,
    margin: `0 auto`,
    padding: `15px`, //rhythm(3 / 4),
}

class DefaultLayout extends Component {
    render() {
        return (
            <div>
                <div
                    css={{
                        background: `rgb(207, 58, 62)`,
                        marginBottom: `15px`, //rhythm(1),
                        padding: `15px 0px`, //`${rhythm(1)} 0px`,
                        "@media screen and (min-width: 500px)": {
                            padding: `15px`, //`${rhythm(2)} 0px`,
                        },
                    }}
                >
                    <div css={containerStyle}>
                        <h1
                            css={{
                                margin: 0,
                                fontSize: `20px`, // scale(1.5).fontSize,
                                lineHeight: 1,
                                "@media screen and (min-width: 500px)": {
                                    fontSize: `25px`, // scale(1.9).fontSize,
                                    lineHeight: 1,
                                },
                            }}
                        >
                            <Link
                                css={{
                                    color: `rgb(224,203,144)`,
                                    ":hover": {
                                        color: `rgb(224,203,144)`,
                                        textDecoration: `none`,
                                    },
                                }}
                                to="/"
                            >
                                Gatsby + Wordpress!!
              </Link>
                        </h1>
                    </div>
                </div>
                <div css={containerStyle}>{this.props.children}</div>
            </div>
        )
    }
}

DefaultLayout.propTypes = {
    children: PropTypes.object.isRequired,
}

export default DefaultLayout